import { Card } from "@/components/atoms/Card/Card.tsx";
import { getFormattedDateAndTime } from "@/utils/date";
import { AudienceSize } from "@/components/molecules/AudienceSize/AudienceSize";
import { FilterEntityTypes } from "@primer/filters/types";
import { GetAudienceResponse } from "@/types/api";
import { CrmMatchRates } from "@/types/audience";
import { useAudienceSizeValidations } from "../../hooks/useAudienceSizeValidations";
import { Separator } from "@/components/atoms/Separator/Separator";
import { Tooltip } from "@/components/atoms/Tooltip/Tooltip";
import { JoinedCriteriaAssets } from "@/hooks/useCriteriaAssetsManager";

interface AudienceEstimateCardHorizontalProps {
    audience?: GetAudienceResponse;
    criteriaAssets?: JoinedCriteriaAssets;
    crmMatchRates?: CrmMatchRates;
    isCriteriaAssetsLoading?: boolean;
    isLoadingAudience: boolean;
}

export const AudienceEstimateCardHorizontal = ({
    audience,
    criteriaAssets,
    crmMatchRates,
    isLoadingAudience,
    isCriteriaAssetsLoading,
}: AudienceEstimateCardHorizontalProps) => {
    const { smallAudienceErrorMessage, estimates, showAudienceSize, shouldShowError, exceeded } = useAudienceSizeValidations({
        audience,
        criteriaAssets,
        isCriteriaAssetsLoading,
    });

    return (
        <Card
            id="horizontal-estimate-card"
            className="flex w-full h-[100px] pt-[24px] pb-[24px] pl-[32px] pr-[32px] gap-[24px] overflow-x-auto"
        >
            <AudienceSize
                entityType={FilterEntityTypes.COMPANY}
                estimate={estimates[FilterEntityTypes.COMPANY]}
                sizeWarning={showAudienceSize ? false : exceeded[FilterEntityTypes.COMPANY]}
                className="text-nowrap"
                horizontal={true}
                loading={isCriteriaAssetsLoading || isLoadingAudience}
                showAudienceSize={showAudienceSize}
                id={audience?.id}
            />
            <AudienceSize
                entityType={FilterEntityTypes.PERSON}
                estimate={estimates[FilterEntityTypes.PERSON]}
                sizeWarning={
                    showAudienceSize ? false : exceeded[FilterEntityTypes.PERSON] || !!smallAudienceErrorMessage
                }
                sizeError={showAudienceSize ? false : !!smallAudienceErrorMessage}
                className="text-nowrap"
                horizontal={true}
                loading={isCriteriaAssetsLoading || isLoadingAudience}
                showAudienceSize={showAudienceSize}
                crmMatchRates={crmMatchRates}
                id={audience?.id}
            />
            {shouldShowError && audience?.id && (exceeded[FilterEntityTypes.COMPANY] || exceeded[FilterEntityTypes.PERSON]) &&
                <div className="px-[16px] py-[12px] rounded-lg max-w-[210px] h-[53px] bg-warning-background text-warning">
                    <div className="text-xs text-start line-clamp-2" title="Audience sizes are limited to a maximum of 3 million records.">
                        Audience sizes are limited to a maximum of 3 million records.
                    </div>
                </div>}
            {shouldShowError && audience?.id && smallAudienceErrorMessage &&
                <Tooltip side="bottom" content={smallAudienceErrorMessage} className="text-wrap text-left !max-w-[300px]" fullWidth>
                    <div className="px-[16px] py-[12px] rounded-lg max-w-[210px] h-[53px] bg-error-background text-error">
                        <div className="text-xs text-start line-clamp-2">{smallAudienceErrorMessage}</div>
                </div>
                </Tooltip>}
            {audience?.lastUpdatedAt && <Separator orientation="vertical" className="" />}
            {audience?.lastUpdatedAt && (
                <div className="flex flex-col justify-start whitespace-nowrap">
                    <p className="text-[10px] text-ui-300 uppercase">Last Updated At</p>
                    <p className="text-sm text-ui-900 mt-2">
                        {getFormattedDateAndTime(new Date(audience.lastUpdatedAt))}
                    </p>
                </div>
            )}
        </Card>
    );
};
